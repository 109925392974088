<template lang="pug">
  div
    div(id="firebaseui-auth-container")
    div.wrap-card-footer.mt12
      span.fz10.text-center.block.mb2 サインイン・サインアップすることであなたは当社の
      span.fz10.text-center.block 
        | #[a(href="/service_terms.pdf" target="brank").pb1 利用規約]・
        | #[a(href="/privacy_policy.pdf" target="brank").pb1 プライバシーポリシー]
        | に同意したことになります
    //- v-progress-circular(v-show="!isSignInUILoaded" :size="50" color="primary" indeterminate)
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-card-footer {
  span {
    color: #fff;
    a {
      color: #fff;
      border-bottom: solid  0.6px rgba(255, 255, 255, 0.4);
    }
  }
}
</style>

<script>
import { firebase } from '@/components/utils/firebase'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
const ui = new firebaseui.auth.AuthUI(firebase.auth())

export default {
  data () {
    return {
      isSignInUILoaded: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setupSignInUi()
    })
    // setTimeout(this.setupSignInUi, 800)
  },
  methods: {
    setupSignInUi () {
      const vInstance = this
      const uiConfig = {
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        callbacks: {
          uiShown () {
            vInstance.isSignInUILoaded = true
          },
          signInSuccessWithAuthResult: () => false
        },
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            fullLabel: (this.$route.name === 'sign-in') ? 'Googleでログイン' : 'Googleでサインアップ',
            buttonColor: '#fff',
            iconUrl: '/icon/google.svg'
          },
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            fullLabel: (this.$route.name === 'sign-in') ? 'Emailでログイン' : 'Emailでサインアップ',
            requireDisplayName: true,
            buttonColor: '#fff',
            iconUrl: '/icon/email.svg'
          }
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        // tosUrl: 'https://chatcenter.io/#service_terms',
        // Privacy policy url/callback.
        // privacyPolicyUrl: function () {
        //   window.location.assign('https://chatcenter.io/#privacy_policy')
        // }
      }

      // Initialize the FirebaseUI Widget using Firebase.

      // The start method will wait until the DOM is loaded.

      // this.$nextTick(() => {
      //   ui.start('#firebaseui-auth-container', uiConfig)
      // })
      setTimeout(() => {
        ui.start('#firebaseui-auth-container', uiConfig)
      }, 800)
    }
  }
}
</script>

<style>
/*
.mdl-card .firebaseui-callback-indicator-container {
  height: 2px;
}
.mdl-card.firebaseui-container {
  min-height: 2px;
} */
</style>
