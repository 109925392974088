<template lang="pug">
  div
    slot(:sign-out="signOut")
    RequireActivation(v-if="uid")
</template>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import RequireActivation from '@/components/shared/RequireActivation'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('auth')

export default {
  components: {
    RequireActivation
  },
  computed: {
    ...mapState([
      'uid',
      'isLoggedIn',
      'isAuthenticating',
      'roles',
      'isAnonymous'
    ])
  },
  watch: {
    // Check if we should login again if authentication info changes
    isAuthenticating (isAuthenticating) {
      this.checkAuthStatus(
        isAuthenticating,
        this.isLoggedIn
      )
    },
    // Check if we should login again if isLoggedIn info changes
    isLoggedIn (isLoggedIn) {
      this.checkAuthStatus(
        this.isAuthenticating,
        isLoggedIn
      )
    }
  },
  created () {
    this.setupAuthStateHandler()

    // Check our auth status when we first start rendering
    this.checkAuthStatus(this.isAuthenticating, this.isLoggedIn)
  },
  methods: {
    ...mapMutations([
      'updateOnboardingData',
      'updateIsSigningOut',
      'updateAuthStatus'
    ]),
    ...mapActions([
      'setupAuthStateHandler',
      'signOut'
    ]),

    // Check if we need to login, or if we are logged in
    async checkAuthStatus (isAuthenticating, isLoggedIn) {
      if (!isAuthenticating && !isLoggedIn) {
        this.$emit('loginFailed')
      } else if (isLoggedIn) {
        this.$emit('loggedIn')

        var user = await db.collection('TRAINERS')
          .doc(this.uid)
          .get()
          .then(d => {
            return d.data()
          })
        if (user) {
          user.lastSignInTime = new Date()
          db.collection('TRAINERS')
            .doc(this.uid)
            .update({ lastSignInTime: new Date() })
        }

        // mixpanel.identify(this.uid)
        // mixpanel.people.set(user)
      }

      if (this.isAnonymous) {
        // mixpanel.identify(this.uid)
        // mixpanel.register({
        //   userId: this.uid,
        //   name: 'Anonymous'
        // })
        // mixpanel.people.set({
        //   '$last_login': new Date(),
        //   userId: this.uid,
        //   name: 'Anonymous'
        // })
      } else {
        // mixpanel.identify(this.uid)
        // mixpanel.register({
        //   userId: this.uid,
        //   name: this.uid
        // })
        // mixpanel.people.set({
        //   '$last_login': new Date(),
        //   userId: this.uid,
        //   name: this.uid
        // })
      }
    }
  }
}
</script>
