<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    div.wrap-sign-ui.f.fh
      div.sign-ui
        div.f.fc.mb40
          div.wrap-logo.f.fh
            img(v-if="$route.name === 'sign-in' || $route.name === 'sign-up'"
              src="/img/habit-logo.svg").logo-karte.object-fit-cover
            //- img(v-if="$route.name === 'client-sign-in' || $route.name === 'client-sign-up'"
              src="/img/habit-logo.jpg").logo-habit
        div.mb24
          SignIn(:redirect="resultRedirect")
        div.wrap-select-sign-in-up.f.fc
          span.py4(
            v-if="$route.name === 'sign-up'"
            @click="$router.push('/sign-in')").fz12 アカウントをお持ちの方
          span.py4(
            v-if="$route.name === 'sign-in'"
            @click="$router.push('/sign-up')").fz12 アカウントをお持ちでない方
        //- div.trainer-or-trainee.f.fc
          span(@click="$router.push('/client-sign-in')"
            v-if="$route.name === 'sign-in'") トレーナーでない方はこちら
          span(@click="$router.push('/client-sign-up')"
            v-if="$route.name === 'sign-up'") トレーナーでない方はこちら
          span(@click="$router.push('/sign-in')"
            v-if="$route.name === 'client-sign-in'") トレーナーの方はこちら
          span(@click="$router.push('/sign-up')"
            v-if="$route.name === 'client-sign-up'") トレーナーの方はこちら
        div.wrap-bg-img
          img(src="/img/habit-bg.svg").object-fit-cover.s-hide
          img(src="/img/habit-bg-sp.svg").object-fit-cover.s-show
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-sign-ui {
  position: relative;
  width: 100%;
  height: 100vh;
  .sign-ui {
    width: 90%;
    z-index: 101;
    .wrap-logo {
      .logo-habit {
        width: 230px;
      }
      .logo-karte {
        width: 220px;
      }
    }
    .welcome {
      font-weight: bold;
      font-size: 28px;
    }
    .trainer-or-trainee {
      span {
        cursor: pointer;
        font-size: 14px;
        color: $active_color;
        // border-bottom: solid #1976d2 0.8px;
      }
    }
  }
  .wrap-bg-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    img {
      width: 100%;
      min-height: 100vh;
    }
  }
}
</style>

<style lang="scss">
.wrap-sign-ui {
  .firebaseui-idp-list, .firebaseui-card-content {
    padding: 0 !important;
  }
  .firebaseui-relative-wrapper {
    padding: 0 24px;
  }
  .firebaseui-card-footer, .firebaseui-provider-sign-in-footer {
    display: none;
  }
  .firebaseui-idp-text-long {
    color: #008080;
    width: 160px;
    text-align: center;
  }
}
</style>

<script>
import { firebase } from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
// import { COLLECTIONS } from '@/enum'
// import { trainingMenuItems } from '@/trainingMenuData'

import Auth from '@/components/auth'
import SignIn from '@/components/sign-in/firebase-sign-in-ui'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    SignIn
  },
  props: {
    redirect: {
      type: String,
      default: 'home'
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    resultRedirect: '/home'
  }),
  computed: {
    ...mapStateAuth(['isLoggedIn', 'uid', 'isAnonymous'])
  },
  created () {
    // if (this.id === '' && this.home !== 'home') this.resultRedirect = `/${this.redirect}`
    // if (this.id !== '' && this.home !== 'home') this.resultRedirect = `/${this.redirect}/${this.id}`
  },
  mounted () {
    setTimeout(() => {
      if (location.pathname.split('/')[1] === 'sign-up') {
        if (document.getElementsByClassName('firebaseui-title')[0]) {
          document.getElementsByClassName('firebaseui-title')[0].innerText = 'Sign Up with Email'
        }
      }
    }, 2000)
  },
  methods: {
    ...mapActionsAuth([
      'signUp',
      'checkTrainerExisting',
      'checkAuthedClientExisting',
      'signUpAsClientUser',
      'signUpAsClientUserWithoutInvitation'
    ]),
    loginFailed () {

    },
    async onLoggedIn () {
      if (this.uid && !this.isAnonymous) {
        var user = await firebase.auth().currentUser

        var trainerExists = await this.checkTrainerExisting(this.uid)
        var traineeExists = await this.checkAuthedClientExisting(this.uid)

        if (this.$route.name === 'sign-in' || this.$route.name === 'sign-up') {
          // トレーナー側のサインイン
          // var authedClientExists = await this.checkAuthedClientExisting(this.uid)

          // トレーナーがサインアップした場合
          if (!trainerExists && !traineeExists) {
            await this.signUp(user)
          }
          if (traineeExists) {
            this.$router.push('/client_inbox')
          } else {
            this.$router.push('/clients')
          }
        } else {
          // トレーニー（クライアント）側のサインイン
          if (!traineeExists && !trainerExists && this.$route.params.clientId) {
            await this.signUpAsClientUser({
              user,
              clientId: this.$route.params.clientId
            })
          }

          if (trainerExists) {
            this.$router.push('/clients')
          } else {
            this.$router.push('/client_inbox')
          }
        }

        // クライアントが招待リンクでサインアップした場合
        // if (!trainerExists && !authedClientExists && this.$route.params.clientId) {
        //   // if redirectId exists,
        //   this.signUpAsClientUser({user: user, clientId: this.$route.params.clientId})
        //   this.$router.push(`/habitlist/${this.uid}`)
        // }

        // クライアントが招待リンクなしでサインアップする場合
        // if (!trainerExists && !authedClientExists && this.$route.name === "client-sign-up") {
        //   this.signUpAsClientUserWithoutInvitation(user)
        //   this.$router.push(`/habitlist/${this.uid}`)
        // }

        // トレーナーがサインアップorサインインした場合
        // if (trainerExists) this.$router.push('/clients')

        // // クライアントがサインインした場合
        // if (authedClientExists) this.$router.push(`/habitlist/${this.uid}`)
      }
    }
  }
}
</script>
